import Link from "next/link";
import { Icons } from "./icons";
import { Logo } from "./nav/nav-header";

const firstSectionLinks = [
  { href: "/docs", text: "Documentation", className: "text-text-secondary" },
  {
    href: "/docs/plugins/vscode",
    text: "Supported Plugins",
    className: "text-text-placeholder",
  },
  {
    href: "/docs/widgets",
    text: "Widgets",
    className: "text-text-placeholder",
  },
];

const secondSectionLinks = [
  {
    href: "/docs/getting-started",
    text: "Getting Started",
    className: "text-text-secondary",
  },
  { 
    href: "/contact", 
    text: "Contact Us", 
    className: "text-text-placeholder" 
  },
  {
    href: "/blog",
    text: "Blog",
    className: "text-text-placeholder",
  }
  /* add future link here */
];

interface Link {
  href: string;
  text: string;
  className: string;
}

const FooterLinksSection = ({ links }: { links: Link[] }) => (
  <div className="flex flex-col justify-start items-start gap-2">
    {links.map((link, index) => (
      <Link
        key={index}
        href={link.href}
        className={`self-stretch ${link.className} text-xs`}
      >
        {link.text}
      </Link>
    ))}
  </div>
);

export default function Footer() {
  return (
    <footer className="border-t">
      <div className="sm:flex-row flex flex-col text-xs container py-10">
        <div className="space-y-2 w-full sm:w-1/3 flex sm:block justify-between items-start pb-8 sm:pb-0">
          <div>
            <Logo title="chronoly" />
            <div className="text-text-placeholder">
              <p>Time Tracking & Analytics for Developers</p>
            </div>
          </div>
          <div className="inline-flex items-center justify-center gap-4 pt-2">
            <Link href="https://github.com/chronoly" target="_blank">
              <Icons.github className="h-5 w-5 text-text-placeholder" />
            </Link>
            <Link href="https://discord.gg/wAKxySJnNJ" target="_blank">
              <Icons.discord className="h-5 w-5 text-text-placeholder" />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 w-full sm:px-10">
          <FooterLinksSection links={firstSectionLinks} />
          <FooterLinksSection links={secondSectionLinks} />
          <FooterLinksSection links={[]} /> {/* For future use */}
          <FooterLinksSection links={[]} /> {/* For future use */}
        </div>
      </div>
      <div className="bg-layer-01">
        <div className="py-3 container text-xs text-text-placeholder">
          <p>© 2024 Chrono.ly. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
