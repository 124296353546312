"use client";

import Cookies from "js-cookie";
import Link from "next/link";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Icons } from "~/app/_components/landing/icons";
import { MainNav } from "~/app/_components/landing/nav/main-nav";
import { buttonVariants } from "~/app/_components/landing/ui/button";
import NavAlertBar from "./nav-alert-bar";

const marketingConfig = {
  mainNav: [
    {
      title: "Documentation",
      href: "/docs",
    },
    {
      title: "Supported Plugins",
      href: "/docs/plugins/vscode",
    },
    {
      title: "Blog",
      href: "/blog",
    },
  ],
};

// This is the Login and Get Started button
const Login = () => {
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const isEnvProd = process.env.VERCEL_ENV === "production";
    const sessionTokenName = `${
      isEnvProd ? "__Secure-" : ""
    }authjs.session-token`;
    const tokenFromCookie = Cookies.get(sessionTokenName);
    setToken(tokenFromCookie); // Update the token state after hydration
  }, []); // Runs only on the client side

  return (
    <nav className="ml-auto space-x-2">
      <a
        href={
          token == undefined
            ? `${process.env.NEXT_PUBLIC_APP_URL}/login`
            : `${process.env.NEXT_PUBLIC_APP_URL}/`
        }
        className={twMerge(
          buttonVariants({ variant: "ghost", size: "sm" }),
          "px-4"
        )}
      >
        {token === undefined ? "Login" : "Dashboard"}
      </a>
      <a
        href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}
        className={twMerge(
          buttonVariants({ variant: "default", size: "sm" }),
          "px-4"
        )}
      >
        Get Started
      </a>
    </nav>
  );
};

// read the name
export const Logo = (props: { title: string; subtitle?: string }) => {
  return (
    <Link href="/" className="mr-auto items-center space-x-2 text-[24px] flex">
      <Icons.logoIcon />
      <span className="font-bold ">{props.title}</span>
      {props.subtitle && <span className="font-light">{props.subtitle}</span>}
    </Link>
  );
};

const NavHeader = (props: { border: boolean }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleBarsIconClick = () => {
    toggleModal();
  };

  return (
    <header className={twMerge("z-40 py-4", props.border ? "border-b" : "")}>
      <div className="container space-y-3">
        <NavAlertBar />
        <div className="flex items-center justify-between px-2">
          <div className="hidden justify-between items-center md:flex w-full">
            <div className="flex gap-6">
              <Logo title="chronoly" />
              <MainNav items={marketingConfig.mainNav} />
            </div>
            <Login />
          </div>
          <div className="flex justify-between items-center w-full md:hidden">
            <Logo title="chronoly" />
            <div className="flex justify-between items-center ">
              <div className="flex justify-end cursor-pointer">
                <Icons.menu
                  onClick={handleBarsIconClick}
                  className="cursor-pointer"
                />
              </div>
            </div>
            {showModal && (
              <div className="fixed inset-0 flex justify-center items-center">
                <div className="absolute inset-0 bg-layer-01" />
                <Icons.X
                  className="absolute top-6 right-4 cursor-pointer"
                  onClick={toggleModal}
                  style={{ fontSize: "16px" }}
                />
                <div className="relative w-full">
                  <div className="flex flex-col gap-8 items-center justify-center h-full">
                    {/* <Link h className="font-light text-2xl cursor-pointer">
                        Link 1
                      </span> */}
                    <Link
                      href={"/"}
                      onClick={toggleModal}
                      className={twMerge(
                        "flex items-center text-xl font-medium transition-colors"
                      )}
                    >
                      Home
                    </Link>
                    {marketingConfig.mainNav?.map((item, index) => (
                      <Link
                        key={index}
                        href={item.href}
                        onClick={toggleModal}
                        className={twMerge(
                          "flex items-center text-xl font-medium transition-colors"
                        )}
                      >
                        {item.title}
                      </Link>
                    ))}
                    <a
                      href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
                      onClick={toggleModal}
                      className={twMerge(
                        "flex items-center text-xl font-medium transition-colors"
                      )}
                    >
                      Log In
                    </a>
                    <a
                      href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}
                      onClick={toggleModal}
                      className={twMerge(
                        "flex items-center text-xl font-medium transition-colors"
                      )}
                    >
                      Register
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavHeader;
